import request from '@/utils/request';

// 赛事 列表
export const competitionList = params => request.post(`study/competition/list`, params)

// 赛事 详情
export const competitionDetail = id => request.get(`study/competition/${id}/detail`)

// 赛事作品 列表
export const competitionEntryList = params => request.post(`study/competition/entry/list`, params)

// 赛事作品 提交
export const competitionEntrySubmit = params => request.post(`study/competition/entry/submit`, params)

// 赛事作品 详情
export const competitionEntryDetail = (id, entryId) => request.get(`study/competition/entry/${id}/${entryId}/1/detail`)

// 赛事作品 删除
export const competitionEntryDel = params => request.post(`study/competition/entry/del`, params)

// 赛事作品 评分
export const competitionEntryScoreSubmit = params => request.post(`study/competition/entry/scoresubmit`, params)

// 赛事作品 评分详情
export const competitionEntryScoretDetail = params => request.post(`study/competition/entry/questionnaire/detail`, params)